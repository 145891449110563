<template>
  <v-theme-provider dark>
    <v-container
      id="info-alt"
      fluid
      class="deep-orange lighten-2"
    >
      <v-row
        no-gutters
        class="ma-2"
      >
        <v-col
          id="leftCol"
          cols="12"
          md="6"
        >
          <v-container
            fluid
            class="deep-purple darken-2"
          >
            <base-info-card title="Contact" />
            <base-business-contact />
          </v-container>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div class=" pa-3 teal darken-1 fill-height">
            <base-info-card title="Links" />
            <router-link
              to="/"
              class=" pl-5 text-uppercase text-h6 font-weight-bold mb-3 text-left white--text"
            >
              Home
            </router-link>
            <v-spacer>
              <router-link
                to="/about"
                class=" pl-5 text-uppercase text-h6 font-weight-bold mb-3 text-left white--text"
              >
                About
              </router-link>
            </v-spacer>
            <router-link
              to="/contact-us"
              class=" pl-5 text-uppercase text-h6 font-weight-bold mb-3 text-left white--text"
            >
              Contact
            </router-link>
            <v-spacer>
              <router-link
                to="/Properties"
                class=" pl-5 text-uppercase text-h6 font-weight-bold mb-3 text-left white--text"
              >
                Properties
              </router-link>
            </v-spacer>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBusinessInfo',

  }
</script>

<style lang="sass">
  #info-alt a
    text-decoration: none
</style>
